import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FairButton from "../../../theme/styled/FairButton";
import Divider from "@mui/material/Divider";

import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { formatSafariFix } from "../../../helpers/functions";

const KYC_URL = process.env.REACT_APP_KYC_URL;

const generateCxtQueryString = IdentityNo => {
	const personalNumber = IdentityNo.replace("-", "");
	const contextObjJasonStr = JSON.stringify({
		customer: { id: personalNumber }
	});

	return btoa(contextObjJasonStr);
};

const BasicInfo = props => {
	const { user, elevatedUser } = props;
	const { initAxios } = useAxios();
	const axiosRequestController = useRef(null);
	const firstUpdate = useRef(true);
	const errorHandler = useErrorHandler();
	const [kycCtxtQueryString, setKycCtxtQueryString] = useState(null);
	const [noKycChecked, setNoKycChecked] = useState(user.NoKycNeeded === "1");
	const [kycToggleError, setKycToggleError] = useState(false);

	const profileCtxtQueryString = user.IdentityNo
		? generateCxtQueryString(user.IdentityNo)
		: null;

	const toggleKycNeeded = (event) => {
		setNoKycChecked(event.target.checked);
		setKycToggleError(false);
	}

	console.log(elevatedUser);

	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		if (user.NoKycNeeded === "1" && noKycChecked) {
			return;
		}

		if (user.NoKycNeeded === "0" && !noKycChecked) {
			return;
		}

		const updateKycRequirement = async () => {
			const { axiosInstance, axiosController } = initAxios("private");
			axiosRequestController.current = axiosController;
			const { data } = await axiosInstance.get("user/kyc_toggle/" + user.LegalEntityId + "/" + (noKycChecked ? "1" : "0"));
			return data;
		};

		updateKycRequirement().then(result => {
			console.log("KYC Check Needed Set To = ", noKycChecked);
			user.NoKycNeeded = noKycChecked ? "1" : "0";
		}).catch(err => {
			setKycToggleError(true);
		});
	}, [noKycChecked]);

	useEffect(() => {
		const getKycCheckString = async () => {
			try {
				const { axiosInstance, axiosController } = initAxios("private");
				axiosRequestController.current = axiosController;
				const { data } = await axiosInstance.get("user/kycCheck");
				return data;
			}
			catch (err) {
				errorHandler.serverError(err);
			}
		};

		if (!kycCtxtQueryString) {
			getKycCheckString().then(checkString => {
				const personalNumber = user.IdentityNo.replace("-", "");
				const contextObjJasonStr = JSON.stringify({
					customer: { id: personalNumber, check: checkString }
				});
				setKycCtxtQueryString(btoa(contextObjJasonStr));
			});
		}
	}, [kycCtxtQueryString, user.IdentityNo, initAxios, errorHandler]);

	return (
		<>
			<Box>
				<Typography variant="h6" align="left">
					Grunduppgifter
				</Typography>
			</Box>
			<Box>
				<Typography
					align="left"
					variant="h7"
					color="common.black"
					component="p"
					sx={{ mt: 2, mb: 1 }}
				>
					Namn
				</Typography>
				<Typography align="left" variant="body2" color="common.black">
					{user.FirstName} {user.LastName} {"("}
					{user.IdentityNo}
					{")"}
				</Typography>
			</Box>
			<Box>
				<Typography
					align="left"
					variant="h7"
					color="common.black"
					component="p"
					sx={{ mt: 2, mb: 1 }}
				>
					Adress
				</Typography>
				<Typography align="left" variant="body2" color="common.black">
					{user.AddressStreet}
				</Typography>
				<Typography align="left" variant="body2" color="common.black">
					{user.AddressZIP} {user.AddressCity}, {user.AddressCountry}
				</Typography>
			</Box>
			<Box>
				<Typography
					align="left"
					variant="h7"
					color="common.black"
					component="p"
					sx={{ mt: 2, mb: 1 }}
				>
					Föranmält konto
				</Typography>
				<Typography align="left" variant="body2" color="common.black">
					{user.BankAccountName
						? user.BankAccountName
						: "Inget föranmält konto registrerat."}
				</Typography>
			</Box>
			<Box
				sx={{
					justifyContent: "flex-start",
					alignItems: "center",
					display: "flex",
					flexFlow: "row"
				}}
			>
				<Box
					sx={{
						justifyContent: "center",
						alignItems: "flex-start",
						display: "flex",
						flexFlow: "column"
					}}
				>
					<Typography
						align="left"
						variant="h7"
						color="common.black"
						component="p"
						sx={{ mt: 2, mb: 1 }}
					>
						Telefonnummer
					</Typography>
					<Typography align="left" variant="body2" color="common.black">
						{user.PhoneMobile
							? user.PhoneMobile
							: user.Phone
								? user.Phone
								: "-"}
					</Typography>
				</Box>
				<Box
					sx={{
						justifyContent: "center",
						alignItems: "flex-start",
						display: "flex",
						flexFlow: "column",
						ml: 5
					}}
				>
					<Typography
						align="left"
						variant="h7"
						color="common.black"
						component="p"
						sx={{ mt: 2, mb: 1 }}
					>
						Alternativt telefonnummer
					</Typography>
					<Typography align="left" variant="body2" color="common.black">
						{user.PhoneAlternative ? user.PhoneAlternative : "-"}
					</Typography>
				</Box>
			</Box>
			<Box>
				<Typography
					align="left"
					variant="h7"
					color="common.black"
					component="p"
					sx={{ mt: 2, mb: 1 }}
				>
					E-postadress
				</Typography>
				<Typography align="left" variant="body2" color="common.black">
					{user.Email ? user.Email : "-"}
				</Typography>
			</Box>
			<Box>
				{profileCtxtQueryString ? (
					<FairButton
						sx={{ mt: 3 }}
						href={`https://cob.kundinfo.fairinvestments.se/fair-kundinfo?context=${profileCtxtQueryString}`}
						rel="noopener noreferrer"
						target="_blank"
					>
						Ändra grunduppgifter
					</FairButton>
				) : (
					<Typography
						align="left"
						variant="body2"
						color="common.black"
						sx={{ mt: 3, fontSize: "italic" }}
					>
						För att ändra dina grunduppgifter, var god ta kontakt med
						kundtjänst.
					</Typography>
				)}
			</Box>
			<Divider sx={{ my: 3 }} />
			<Box>
				<Typography variant="h7" align="left" color="common.black">
					Kundkännedomsuppgifter
				</Typography>
			</Box>
			<Box>
				<Typography
					align="left"
					variant="body2"
					color="common.black "
					sx={{ maxWidth: "640px", mt: 1 }}
				>
					Vi är enligt lag skyldiga att inhämta information om våra kunder och
					deras finansiella transaktioner samt se till så att de tjänster och
					produkter som vi erbjuder är lämpliga baserat på kundens situation och
					mål. Svaren på dessa frågor måste uppdateras med jämna mellanrum för
					att följa regelverk om bl.a. penningtvätt och finansiering av
					terrorism.
				</Typography>
				<Box style={{ display: "flex", width: "100%" }}>
					<Typography
						align="left"
						variant="body2"
						color="common.black"
						sx={{ mt: 1 }}
						style={{ flex: "1", display: "flex", flexWrap: "wrap", alignItems: "flex-start" }}
					>
						Kundkännedom senast uppdaterad:&nbsp;{" "}
						<b>
							{user.KycCompleted && user.KycCompleted !== "0000-00-00 00:00:00"
								? formatSafariFix(user.KycCompleted, "yyyy-MM-dd")
								: "-"}
						</b>
						{!kycToggleError && noKycChecked && (
							<React.Fragment>
								<Typography variant="body2" color="error.main" style={{width: "100%", fontStyle: "italic"}}>
									KYC-kontroll avstängd
								</Typography>
							</React.Fragment>
						)}
						{kycToggleError && (
							<React.Fragment>
								<Typography variant="body2" color="error.main" style={{width: "100%", fontStyle: "italic"}}>
									KYC-kontroll kunde ej sparas. Var god kontakta teknisk support om felet kvarstår.
								</Typography>
							</React.Fragment>
						)}
					</Typography>

					{elevatedUser && (elevatedUser.Role === 'Backoffice' || elevatedUser.Role === 'Admin') && (
						<Box sx={{ mt: 1 }} style={{ display: "flex", flexDirection: "column" }}>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox style={{paddingTop: "0", paddingBottom: "0"}} checked={noKycChecked} onChange={toggleKycNeeded} />}
									label={<Typography variant="body2">Stäng av KYC-kontroll för denna kund</Typography>} />
							</FormGroup>
						</Box>
					)}
				</Box>

				{!noKycChecked && (
					<React.Fragment>
						<FairButton
							sx={{ my: 3 }}
							href={`${KYC_URL}?context=${kycCtxtQueryString}`}
							rel="noopener noreferrer"
							target="_blank"
						>
							Öppna formulär för kundkännedom
						</FairButton>
					</React.Fragment>
				)}
			</Box>
		</>
	);
};

export default BasicInfo;

BasicInfo.propTypes = {
	user: PropTypes.object,
	elevatedUser: PropTypes.object
};
